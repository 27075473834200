<template>
  <!--MODAL COPY DAY-->
  <b-modal
    id="copy-day-modal"
    :title="$t('broadcast.copyDay')"
    :ok-disabled="$v.$invalid"
    :ok-title="$t('broadcast.copy')"
    :cancel-title="$t('table.cancel')"
    :busy="isModalBusy"
    @ok.prevent="showModalCopyDayConfirm"
    @show="clearData()"
  >
    <b-form-group :label="$t('channelModal.from')" label-for="copy-day-datepicker-buttons-from">
      <datepicker-wrapper id="copy-day-datepicker-buttons-from" v-model="copyDay.dateFrom" :disabled="isModalBusy" required />
    </b-form-group>
    <b-form-group :label="$t('channelModal.on')" label-for="copy-day-datepicker-buttons-to">
      <datepicker-wrapper id="copy-day-datepicker-buttons-to" v-model="copyDay.dateTo" :initial-date="copyDay.dateFrom" :disabled="isModalBusy" required />
    </b-form-group>
  </b-modal>
</template>

<script>
import { required, not, sameAs } from 'vuelidate/lib/validators';
import errorsHandler from '@/utils/errorsHandler';
import DatepickerWrapper from '@/components/DatepickerWrapper.vue';
import convertDate from '@/filters/convertDate';

// function isDateToBiggerTo() {
//   return new Date(this.copyDay.dateTo) > new Date(this.copyDay.dateFrom);
// }

export default {
  components: { DatepickerWrapper },
  filters: { convertDate },
  props: {
    channel: {
      required: true,
      type: Object,
    },
  },
  // defineEmits<{
  //   (e: 'dayCopied'): void,
  // }>()
  data() {
    return {
      copyDay: {
        dateFrom: '',
        dateTo: '',
      },
      isModalBusy: false,
    };
  },
  validations: {
    copyDay: {
      dateFrom: { required, notSameAsDateTo: not(sameAs('dateTo')) },
      dateTo: { required /*isDateToBiggerTo*/ },
    },
  },
  methods: {
    clearData() {
      this.copyDay.dateFrom = '';
      this.copyDay.dateTo = '';
    },

    async showModalCopyDayConfirm() {
      const body = this.$createElement('p', {
        domProps: {
          innerHTML: `${this.$i18n.t('broadcast.confirmCopyDay')} <span class="text-danger">${convertDate(this.copyDay.dateFrom)} ->
          ${convertDate(this.copyDay.dateTo)}</span> ?`,
        },
      });
      const modalResult = await this.$bvModal
        .msgBoxConfirm(body, {
          title: this.$i18n.t('broadcast.copyDay'),
          size: 'sm',
          okTitle: this.$i18n.t('table.yes'),
          cancelTitle: this.$i18n.t('table.no'),
          autoFocusButton: 'ok',
          id: 'copy-day-confirm-modal',
        })
        .catch((err) => {});
      if (!modalResult) return;
      this.postCopyDay();
    },

    async showModalCopyDayConfirmRewrite() {
      const modalResult = await this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('broadcast.confirmCopyDayRewrite')}`, {
          title: this.$i18n.t('broadcast.existData'),
          size: 'sm',
          okTitle: this.$i18n.t('table.yes'),
          cancelTitle: this.$i18n.t('table.no'),
          autoFocusButton: 'ok',
          id: 'copy-day-confirm-rewrite-modal',
        })
        .catch((err) => {});
      if (!modalResult) return;
      this.postCopyDay(true);
    },

    async postCopyDay(rewrite_day) {
      this.isModalBusy = true;
      await this.$store.dispatch('POST_BROADCAST_COPY_DAY', {
        data: {
          channel: this.channel.id,
        },
        date: {
          date_from: this.copyDay.dateFrom,
          date_to: this.copyDay.dateTo,
          force: rewrite_day,
        },
        handler: (res) => {
          const filledRes = res.data.data?.filled;
          if (filledRes?.spots > 0) {
            this.$notify({
              type: 'warning',
              duration: 4000,
              title: this.$i18n.t('alert.cantCopyInDayWithSpots'),
            });
          } else if (filledRes?.spots === 0 && (filledRes?.blocks > 0 || filledRes?.program_releases > 0)) {
            this.showModalCopyDayConfirmRewrite();
          } else {
            this.$notify({
              type: 'success',
              duration: 2000,
              title: this.$i18n.t('alert.copiedDay'),
              text: `<p class="mb-0 font-weight-bold">${this.$i18n.t('alert.copied')}: </p>
                    ${this.$i18n.t('alert.programReleasesGC')}: ${res.data.data?.copied?.program_releases}<br/>
                    ${this.$i18n.t('alert.blocksGC')}: ${res.data.data?.copied?.blocks}<br/>
                    <p class="mt-1 mb-0 font-weight-bold">${this.$i18n.t('alert.notCopied')}: </p>
                    ${this.$i18n.t('alert.programReleasesGC')}: ${res.data.data?.not_copied?.program_releases}<br/>
                    ${this.$i18n.t('alert.blocksGC')}: ${res.data.data?.not_copied?.blocks}`,
            });
            this.$bvModal.hide('copy-day-modal');
            this.$emit('dayCopied');
            this.clearData();
          }
        },
        handlerError: (errors) => errorsHandler(errors, this.$notify),
      });
      this.isModalBusy = false;
    },
  },
};
</script>
